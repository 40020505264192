import React from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PermissionDenied() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <Result
      status="403"
      title={t("Permission_Denied")}
      subTitle={t("Sorry_not_authorized")}
      extra={
        <Button onClick={() => navigate(-1)} type="primary">
          {t("Back_to_previous_page")}
        </Button>
      }
    />
  );
}

export default PermissionDenied;
