import axios from "axios";
import { API_BASE } from "./constants";
import { createBrowserHistory } from "history";

export const myhistory = createBrowserHistory();

const api = axios.create({
  baseURL: API_BASE,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Credentials": "true",
    // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    // "Access-Control-Allow-Headers":
    //   "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    // "Cache-Control": null,
    // "X-Requested-With": null,
  },
});

const apiRefresh = axios.create({
  baseURL: API_BASE,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Credentials": "true",
    // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    // "Access-Control-Allow-Headers":
    //   "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    // "Cache-Control": null,
    // "X-Requested-With": null,
  },
});

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshToken();
      return api(originalRequest);
    }
    return Promise.reject(error);
  }
);

async function refreshToken(error) {
  return new Promise((resolve, reject) => {
    try {
      const refresh_token = localStorage.getItem("refresh");
      apiRefresh
        .post("/token/refresh/", { refresh: refresh_token })
        .then(async (res) => {
          localStorage.setItem("access", res.data.access);
          localStorage.setItem("refresh", res.data.refresh);
          return resolve(res);
        })
        .catch((err) => {
          myhistory.replace("/login");
          myhistory.go(0);
          localStorage.setItem("access", "");
          localStorage.setItem("refresh", "");
          return reject(error);
        });
    } catch (err) {
      return reject(err);
    }
  });
}

export default api;
