import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "antd/dist/antd.css";
import "./utils/i18n";
import AOS from "aos";
import "aos/dist/aos.css";
import { HelmetProvider } from "react-helmet-async";

AOS.init({
  duration: 600,
  easing: "ease-in-out",
});

ReactDOM.render(
  <>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </>,

  document.getElementById("root")
);
