import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import {API_BASE, API_URL} from '../utils/constants'
// import { notificationError } from "../utils/helperFunctions";


// Creating a state context
export const stateContext = createContext();

// Creating custom state provider
export const StateProvider = ({ children }) => {
  const [isAuthenticated, setisAuthenticated] = useState(() =>
    localStorage.getItem("access") ? true : false
  );
  const [user, setuser] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).user_type
      : null
  );
  const [user_id, setuser_id] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).user_id
      : null
  );
  const [subscription, setsubscription] = useState(() =>
    localStorage.getItem("subscription")
      ? JSON.parse(localStorage.getItem("subscription"))
      : []
  );
  const [canEditContent, setcanEditContent] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).can_edit_content
      : null
  );
  const [canViewDashboard, setcanViewDashboard] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).can_view_dashboard
      : null
  );
  const [canDeleteContent, setcanDeleteContent] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).can_delete_content
      : null
  );
  const [trial_period_end, settrial_period_end] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).trial_period_end
      : null
  );
  const [date_billing_start, setdate_billing_start] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).date_billing_start
      : null
  );
  const [date_billing_end, setdate_billing_end] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).date_billing_end
      : null
  );
  const [date_billing_last, setdate_billing_last] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).date_billing_last
      : null
  );
  const [user_tenant_id, setuser_tenant_id] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).tenant
      : null
  );
  const [user_marketer_id, setuser_marketer_id] = useState(() =>
    localStorage.getItem("access")
      ? jwt_decode(localStorage.getItem("access")).marketer
      : null
  );
  const [userBranches, setuserBranches] = useState(() =>
    localStorage.getItem("userBranches")
      ? JSON.parse(localStorage.getItem("userBranches"))
      : []
  );
  const [userRoles, setuserRoles] = useState(() =>
    localStorage.getItem("userRoles")
      ? JSON.parse(localStorage.getItem("userRoles"))
      : []
  );
  const [loginFailed, setloginFailed] = useState(false);
  const [loading, setloading] = useState(false);

  // For programatically changing routes
  const navigate = useNavigate();

  // Loging user in and setting all default global state
  let loginUser = async (values) => {
    localStorage.clear();
    try {
      setloading(true);
      let response = await axios.post(API_URL, {
        username: values.username,
        password: values.password,
      });

      if (response.status === 200) {
        const user_type = jwt_decode(response.data.access).user_type;
        const tenant_id = jwt_decode(response.data.access).tenant;
        localStorage.setItem("access", response.data.access);
        localStorage.setItem("refresh", response.data.refresh);
        setisAuthenticated(true);
        setloginFailed(false);
        setuser(user_type);
        setuser_tenant_id(tenant_id);
        setuser_marketer_id(jwt_decode(response.data.access).marketer);
        setuser_id(jwt_decode(response.data.access).user_id);
        setcanEditContent(
          jwt_decode(localStorage.getItem("access")).can_edit_content
        );
        setcanViewDashboard(
          jwt_decode(localStorage.getItem("access")).can_view_dashboard
        );
        setcanDeleteContent(
          jwt_decode(localStorage.getItem("access")).can_delete_content
        );
        settrial_period_end(
          jwt_decode(localStorage.getItem("access")).trial_period_end
        );
        setdate_billing_end(
          jwt_decode(localStorage.getItem("access")).date_billing_end
        );
        setdate_billing_start(
          jwt_decode(localStorage.getItem("access")).date_billing_start
        );
        setdate_billing_last(
          jwt_decode(localStorage.getItem("access")).date_billing_last
        );

        // Fetching user branches to be available globally
        if (user_type === "business") {
          try {
            let branch_response = await axios.get(
              `${API_BASE}branchs/?tenant_id=${tenant_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
              }
            );

            localStorage.setItem(
              "userBranches",
              JSON.stringify(branch_response.data)
            );
            setuserBranches(branch_response.data);
          } catch (err) {
            //pass
            console.log(err.response);
          }
          try {
            let subscription_response = await axios.get(
              `${API_BASE}subscription-plans/${
                jwt_decode(localStorage.getItem("access")).subscription_id
              }`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
              }
            );
            localStorage.setItem(
              "subscription",
              JSON.stringify(subscription_response.data)
            );
            setsubscription(subscription_response.data);
          } catch (err) {
            //pass
            console.log(err.response);
          }
          try {
            let roles_response = await axios.get(
              `${API_BASE}user-roles/?tenant_id=${tenant_id}`,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access")}`,
                },
              }
            );
            localStorage.setItem(
              "userRoles",
              JSON.stringify(roles_response.data)
            );
            setuserRoles(roles_response.data);
          } catch (err) {
            //pass
          }
        }
        navigate("/dashboard", { replace: true });
      }
    } catch (err) {
      // notificationError(err);
      setloading(false);
      setloginFailed(true);
      navigate("/login", { replace: true });
    }
  };

  let logoutUser = () => {
    localStorage.clear();
    setloading(false);
    setisAuthenticated(false);
    navigate("/login", { replace: true });
  };

  let setloginfail = () => setloginFailed(false);

  let refreshUserBranch = async () => {
    // Fetching user branches to be available globally
    if (user === "business") {
      try {
        let branch_response = await axios.get(
          `${API_BASE}branchs/?tenant_id=${user_tenant_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        );
        localStorage.setItem(
          "userBranches",
          JSON.stringify(branch_response.data)
        );
        setuserBranches(branch_response.data);
      } catch (err) {
        //pass
      }
    }
  };

  let refreshUserRoles = async () => {
    // Fetching user branches to be available globally
    if (user === "business") {
      try {
        let roles_response = await axios.get(
          `${API_BASE}user-roles/?tenant_id=${user_tenant_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access")}`,
            },
          }
        );
        localStorage.setItem("userRoles", JSON.stringify(roles_response.data));
        setuserRoles(roles_response.data);
      } catch (err) {
        //pass
      }
    }
  };

  let refreshUserBranchwhenDeeleted = async (branch_id) => {
    setuserBranches(userBranches.filter((data) => data.id !== branch_id));
    localStorage.setItem("userBranches", JSON.stringify(userBranches));
  };

  let refreshUserRoleswhenDeeleted = async (role_id) => {
    setuserRoles(userRoles.filter((data) => data.id !== role_id));
    localStorage.setItem("userRoles", JSON.stringify(userRoles));
  };

  const contextData = {
    isAuthenticated: isAuthenticated,
    user: user,
    user_id: user_id,
    subscription: subscription,
    date_billing_end: date_billing_end,
    date_billing_last: date_billing_last,
    date_billing_start: date_billing_start,
    trial_period_end: trial_period_end,
    user_tenant_id: user_tenant_id,
    user_marketer_id: user_marketer_id,
    canEditContent: canEditContent,
    canViewDashboard: canViewDashboard,
    canDeleteContent: canDeleteContent,
    loginUser: loginUser,
    logoutUser: logoutUser,
    loginFailed: loginFailed,
    setloginfail: setloginfail,
    loading: loading,
    userBranches: userBranches,
    refreshUserBranch: refreshUserBranch,
    refreshUserBranchwhenDeeleted: refreshUserBranchwhenDeeleted,
    userRoles: userRoles,
    refreshUserRoles: refreshUserRoles,
    refreshUserRoleswhenDeeleted: refreshUserRoleswhenDeeleted,
  };

  return (
    <stateContext.Provider value={contextData}>
      {children}
    </stateContext.Provider>
  );
};
