import { Navigate, useLocation } from "react-router";
import { stateContext } from "../ContextApi/stateProvider";
import { useContext, useEffect, useState } from "react";
import { notification } from "antd";
import PermissionDenied from "../components/permissionDenied";
import axios from "./Api";
import moment from "moment";
import * as XLSX from 'xlsx';

// Authenntication wrapper function
export const RequireAuth = ({ children }) => {
  const location = useLocation();

  const { isAuthenticated } = useContext(stateContext);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};

export const RequireBusinessUserRoleAuthoriztion = ({ rule, children }) => {
  const { user, user_id } = useContext(stateContext);
  const [userInfo, setuserInfo] = useState({
    can_view_branch: false,
    can_view_products: false,
    can_view_pos: false,
    can_view_services: false,
    can_view_debts: false,
    can_view_expenses: false,
    can_view_users: false,
    can_view_payments: false,
    can_view_invoice: false,
    can_message_kb_admins: false,
  });

  const returnValue = () => {
    if ((user === "business") & userInfo[rule]) {
      return children;
    }
    if (!((user === "business") & userInfo[rule])) {
      return <PermissionDenied />;
    }
  };
  useEffect(() => {
    axios
      .get(`/user-info/${user_id}`)
      .then((res) => setuserInfo(res.data))
      .catch((err) => console.log(err.response));
  }, [user_id]);

  return <>{returnValue()}</>;
};

export const RequireSubscriptionAuthoriztion = ({ children }) => {
  const { user, subscription } = useContext(stateContext);

  const returnValue = () => {
    if ((user === "business") & subscription?.can_view_user_modules) {
      return children;
    }
    if (!((user === "business") & subscription?.can_view_user_modules)) {
      return <PermissionDenied />;
    }
  };
  return <>{returnValue()}</>;
};

export const IsSubscriptionActive = () => {
  const { user, date_billing_end, date_billing_last } =
    useContext(stateContext);

  const today = moment();
  if (user === "business") {
    if ((today > date_billing_end) | (today > moment(date_billing_last))) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export const IsSubscriptionPendingDays = () => {
  const { user, date_billing_end, date_billing_last } =
    useContext(stateContext);
  const today = moment();
  if (user === "business") {
    if (
      (today > moment(date_billing_end)) &
      (today <= moment(date_billing_last))
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const ShowContentSubscriptionActive = () => {
  if (IsSubscriptionActive() | IsSubscriptionPendingDays()) {
    return true;
  } else {
    return false;
  }
};
// Scroll to top wrapper function
export const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>;
};

// Notification error display function
export const notificationError = (error) => {
  try {
    let message = Object.entries(error.response.data.error.details)?.map(
      ([k, v], index) => (
        <span key={index}>
          {k}: {v} <br />
        </span>
      )
    );
    notification.error((message = { message }));
  } catch {
    //pass
  }
};


export function filterDataByDateInput(
  data,
  date,
  type = "date",
  date_var = "created_date",
  range = undefined
) {
  if ((type === "date") & (typeof range === "string")) {
    return data.filter(
      (data) =>
        moment(data[date_var]).format("YYYY-MM-DD") ===
        date.format("YYYY-MM-DD")
    );
  } else if ((type === "month") & (typeof range === "string")) {
    return data.filter(
      (data) =>
        (moment(data[date_var]).month() === date.month()) &
        (moment(data[date_var]).year() === date.year())
    );
  } else if ((type === "year") & (typeof range === "string")) {
    return data.filter(
      (data) => moment(data[date_var]).year() === date.year()
    );
  } else {
    return data.filter(
      (data) =>
        (moment(data[date_var]) >= moment(range[0])) &
        (moment(data[date_var]) <= moment(range[1]))
    );
  }
}

export function filterDataByDateInputAdmin(
  item,
  data,
  date,
  type = "date",
  range = undefined
) {
  if ((item === "tenants") | (item === "marketers")) {
    if ((type === "date") & (typeof range === "string")) {
      return data.filter(
        (data) =>
          moment(data?.date_joined).format("YYYY-MM-DD") ===
          date.format("YYYY-MM-DD")
      );
    } else if ((type === "month") & (typeof range === "string")) {
      return data.filter(
        (data) =>
          (moment(data?.date_joined).month() === date.month()) &
          (moment(data?.date_joined).year() === date.year())
      );
    } else if ((type === "year") & (typeof range === "string")) {
      return data.filter(
        (data) => moment(data?.date_joined).year() === date.year()
      );
    } else {
      return data.filter(
        (data) =>
          (moment(data?.date_joined) >= moment(range[0])) &
          (moment(data?.date_joined) <= moment(range[1]))
      );
    }
  } else {
    if ((type === "date") & (typeof range === "string")) {
      return data.filter(
        (data) =>
          moment(data?.payment_date).format("YYYY-MM-DD") ===
          date.format("YYYY-MM-DD")
      );
    } else if ((type === "month") & (typeof range === "string")) {
      return data.filter(
        (data) =>
          (moment(data?.payment_date).month() === date.month()) &
          (moment(data?.payment_date).year() === date.year())
      );
    } else if ((type === "year") & (typeof range === "string")) {
      return data.filter(
        (data) => moment(data?.payment_date).year() === date.year()
      );
    } else {
      return data.filter(
        (data) =>
          (moment(data?.payment_date) >= moment(range[0])) &
          (moment(data?.payment_date) <= moment(range[1]))
      );
    }
  }
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatFloat(num, decimalPlaces) {
  var result = parseFloat(num).toFixed(decimalPlaces);

  // Remove trailing zeros
  result = result.replace(/\.0+$/, '');
  result = result.replace(/(\.\d+[1-9])0+$/, '$1');

  return result;
}


// Searching the tables
export function search(data, searchTerm) {
  searchTerm = searchTerm.toLowerCase();

  return data.filter(item => {
      return JSON.stringify(item).toLowerCase().includes(searchTerm);
  });
}

//Downloading data in excel format
export function downloadExcel(data, name, columns=["id","branch.branch_name"]) {
  const headers = [...new Set(columns)]; // removes duplicates
  
  const dataAsArrays = data.map(obj => 
      columns.map(column => {
          let value = obj;
          column.split('.').forEach(item => {
              value = value[item];
          });
          return value;
      })
  );
  const ws_data = [headers, ...dataAsArrays];
  const worksheet = XLSX.utils.aoa_to_sheet(ws_data); // convert array of arrays to worksheet
  const workbook = XLSX.utils.book_new(); // create a new blank workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1"); // append the worksheet to the workbook
  XLSX.writeFile(workbook, `${name} - Data.xlsx`); // write the workbook to a file
};

