import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import "./App.css";
import {
  RequireAuth,
  RequireBusinessUserRoleAuthoriztion,
  RequireSubscriptionAuthoriztion,
  ScrollToTop,
} from "./utils/helperFunctions";
import { StateProvider } from "./ContextApi/stateProvider";
import { myhistory } from "./utils/Api";

const DashboardHome = React.lazy(() => import("./pages/dashboardHome"));
const LandingPage = React.lazy(() => import("./pages/landingPage"));
const PrivacypolicyDisplay = React.lazy(() => import("./components/pdfDisplayPage"));
const Login = React.lazy(() => import("./pages/Login"));
const MarketerRegistration = React.lazy(() =>
  import("./pages/marketerPages/marketerRegistration")
);
const PageNotFound = React.lazy(() => import("./components/pageNotFound"));
const MarketerID = React.lazy(() => import("./pages/marketerPages/Profile"));
const DashboardLayout = React.lazy(() =>
  import("./components/dashboardLayout")
);
const TutorialPage = React.lazy(() => import("./pages/tutorialPage"));
const MarketerPaymentPage = React.lazy(() =>
  import("./pages/marketerPages/marketerPaymentPage")
);
const MarketerRefPaymentPage = React.lazy(() =>
  import("./pages/marketerPages/marketerRefPaymentPage")
);
const AdminMarketerPaymentPage = React.lazy(() =>
  import("./pages/adminPages/adminmarketerPaymentPage")
);
const AdminMarketerRefPaymentPage = React.lazy(() =>
  import("./pages/adminPages/adminmarketerRefPaymentPage")
);
const MarketerShopPage = React.lazy(() =>
  import("./pages/marketerPages/marketerShopPage")
);
const MarketerReferralsPage = React.lazy(() =>
  import("./pages/marketerPages/marketerReferralsPage")
);
const BusinessRegistration = React.lazy(() =>
  import("./pages/businessPages/businessRegistration")
);
const BusinessBranch = React.lazy(() =>
  import("./pages/businessPages/businessBranch")
);
const BusinessServices = React.lazy(() =>
  import("./pages/businessPages/businessServices")
);
const BusinessExpenses = React.lazy(() =>
  import("./pages/businessPages/businessExpenses")
);
const BusinessDebt = React.lazy(() =>
  import("./pages/businessPages/businessDebt")
);
const BusinessPaymentPage = React.lazy(() =>
  import("./pages/businessPages/businessPaymentPage")
);
const AdminBusinessPaymentPage = React.lazy(() =>
  import("./pages/adminPages/adminbusinessPaymentPage")
);
const BusinessProducts = React.lazy(() =>
  import("./pages/businessPages/businessProducts")
);
const BusinessProductDamaged = React.lazy(() =>
  import("./pages/businessPages/businessProductDamaged")
);
const BusinessPOS = React.lazy(() =>
  import("./pages/businessPages/businessPOS")
);
const AdminMessage = React.lazy(() => import("./pages/adminMessage"));
const AdminShopPage = React.lazy(() =>
  import("./pages/adminPages/adminShopPage")
);
const AdminMarketersPage = React.lazy(() =>
  import("./pages/adminPages/adminMarketersPage")
);
const AdminNotifyUsers = React.lazy(() =>
  import("./pages/adminPages/adminNotifyUsers")
);
const AdminAddTutorial = React.lazy(() =>
  import("./pages/adminPages/adminAddTutorial")
);
const AdminUserMessages = React.lazy(() =>
  import("./pages/adminPages/adminUserMessages")
);
const AdminSubscriptionPlan = React.lazy(() =>
  import("./pages/adminPages/adminSubscriptionPlan")
);
const AdminCommision = React.lazy(() =>
  import("./pages/adminPages/adminCommision")
);
const ForgotPassword = React.lazy(() => import("./pages/forgotPassword"));

const BusinessUsers = React.lazy(() =>
  import("./pages/businessPages/businessUsers")
);
const BusinessUserRoles = React.lazy(() =>
  import("./pages/businessPages/businessUserRoles")
);
const BusinessCustomers = React.lazy(() =>
  import("./pages/businessPages/businessCustomers")
);
const BusinessInvoices = React.lazy(() =>
  import("./pages/businessPages/businessInvoices")
);
const BusinessInvoiceDetail = React.lazy(() =>
  import("./pages/businessPages/businessInvoiceDetail")
);
const BusinessInvoicesEdit = React.lazy(() =>
  import("./pages/businessPages/businessInvoicesEdit")
);
const Profile = React.lazy(() => import("./pages/profile"));

function App() {
  return (
    <React.Fragment>
      <BrowserRouter history={myhistory}>
        <StateProvider>
          <ScrollToTop />
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <LandingPage />
                  </Suspense>
                }
              ></Route>
              <Route
                path="ref/marketer/register"
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <MarketerRegistration />
                  </Suspense>
                }
              />
              <Route
                path="ref/business/register"
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <BusinessRegistration />
                  </Suspense>
                }
              />
              <Route
                path="login"
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <Login />
                  </Suspense>
                }
              />

              <Route
                path="privacy-policy"
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <PrivacypolicyDisplay />
                  </Suspense>
                }
              />

              <Route
                path="dashboard"
                element={
                  <RequireAuth>
                    <Suspense fallback={<Spin size="large" />}>
                      <DashboardLayout />
                    </Suspense>
                  </RequireAuth>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <DashboardHome />
                    </Suspense>
                  }
                />
                <Route
                  path="profile"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <Profile />
                    </Suspense>
                  }
                />
                {/* Marketer dashboard routes  */}
                <Route
                  path="marketer-shops"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <MarketerShopPage />
                    </Suspense>
                  }
                />
                <Route
                  path="marketer-referrals"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <MarketerReferralsPage />
                    </Suspense>
                  }
                />
                <Route
                  path="payments"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <MarketerPaymentPage />
                    </Suspense>
                  }
                />
                <Route
                  path="referral-payments"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <MarketerRefPaymentPage />
                    </Suspense>
                  }
                />
                <Route
                  path="tutorial"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <TutorialPage />
                    </Suspense>
                  }
                />
                <Route
                  path="kb-id"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <MarketerID />
                    </Suspense>
                  }
                />
                <Route
                  path="message-admin"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminMessage />
                    </Suspense>
                  }
                />
                <Route />

                {/* Shop dashboard routes */}
                <Route
                  path="branches"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_branch"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessBranch />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="services-offered"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_services"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessServices />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="business/payments"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_payments"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessPaymentPage />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="expenses"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_expenses"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessExpenses />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="debts"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_debts"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessDebt />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="customers"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_invoice"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessCustomers />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="invoices"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_invoice"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessInvoices />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="invoices/view/:InvoiceID"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_invoice"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessInvoiceDetail />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="invoices/edit/:InvoiceID"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_invoice"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessInvoicesEdit />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="products"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_products"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessProducts />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="products-damaged"
                  element={
                    <RequireBusinessUserRoleAuthoriztion
                      rule={"can_view_products"}
                    >
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessProductDamaged />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="pos"
                  element={
                    <RequireBusinessUserRoleAuthoriztion rule={"can_view_pos"}>
                      <Suspense fallback={<Spin size="large" />}>
                        <BusinessPOS />
                      </Suspense>
                    </RequireBusinessUserRoleAuthoriztion>
                  }
                />
                <Route
                  path="users"
                  element={
                    <RequireSubscriptionAuthoriztion>
                      <RequireBusinessUserRoleAuthoriztion
                        rule={"can_view_users"}
                      >
                        <Suspense fallback={<Spin size="large" />}>
                          <BusinessUsers />
                        </Suspense>
                      </RequireBusinessUserRoleAuthoriztion>
                    </RequireSubscriptionAuthoriztion>
                  }
                />
                <Route
                  path="user-roles"
                  element={
                    <RequireSubscriptionAuthoriztion>
                      <RequireBusinessUserRoleAuthoriztion
                        rule={"can_view_users"}
                      >
                        <Suspense fallback={<Spin size="large" />}>
                          <BusinessUserRoles />
                        </Suspense>
                      </RequireBusinessUserRoleAuthoriztion>
                    </RequireSubscriptionAuthoriztion>
                  }
                />

                {/* Super admin dashboard routes  */}
                <Route
                  path="admin-shops"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminShopPage />
                    </Suspense>
                  }
                />
                <Route
                  path="marketers"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminMarketersPage />
                    </Suspense>
                  }
                />
                <Route
                  path="notify-users"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminNotifyUsers />
                    </Suspense>
                  }
                />
                <Route
                  path="add-tutorial"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminAddTutorial />
                    </Suspense>
                  }
                />
                <Route
                  path="commission"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminCommision />
                    </Suspense>
                  }
                />
                <Route
                  path="subscription-plans"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminSubscriptionPlan />
                    </Suspense>
                  }
                />
                <Route
                  path="b-payments"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminBusinessPaymentPage />
                    </Suspense>
                  }
                />
                <Route
                  path="m-payments"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminMarketerPaymentPage />
                    </Suspense>
                  }
                />
                <Route
                  path="mreferral-payments"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminMarketerRefPaymentPage />
                    </Suspense>
                  }
                />
                <Route
                  path="user-comments"
                  element={
                    <Suspense fallback={<Spin size="large" />}>
                      <AdminUserMessages />
                    </Suspense>
                  }
                />
              </Route>

              {/* General routes */}
              <Route
                path="forgot-password"
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <ForgotPassword />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<Spin size="large" />}>
                    <PageNotFound />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </StateProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
