import { DatePicker, TimePicker } from "antd";

const DEBUG = process.env.REACT_APP_DEBUG;


export const API_BASE =
  DEBUG === "true"
    ? "https://apitest.kitabubiashara.com/api/"
    : "https://api.kitabubiashara.com/api/";
export const API_URL =
  DEBUG === "true"
    ? "https://apitest.kitabubiashara.com/api/token/"
    : "https://api.kitabubiashara.com/api/token/";

// export const API_BASE =
//   DEBUG === "true"
//     ? "http://localhost:8000/api/"
//     : "https://api.kitabubiashara.com/api/";
// export const API_URL =
//   DEBUG === "true"
//     ? "http://localhost:8000/api/token/"
//     : "https://api.kitabubiashara.com/api/token/";


// // Develoment
// export const API_BASE = "http://localhost:8000/api/";
// export const API_URL = "http://localhost:8000/api/token/";

export const PickerWithType = ({ type, onChange }) => {
  if (type === "time") return <TimePicker onChange={onChange} />;
  if (type === "date") return <DatePicker onChange={onChange} />;
  return <DatePicker picker={type} onChange={onChange} />;
};
